.ui.menu{
  .icon.inverted{
    color: @primaryColor;
  }
}

#invenio-nav.ui.menu {
  height: @relative75px;
  position: relative;

  .ui.menu {
    background-color: transparent;
    box-shadow: none;

    .ui.dropdown.rdm-plus-menu {
      padding: 1rem;
    }

    .item {
      &:hover{
        color: @brandColor
      }

      &.active {
        background-color: lighten(@brandColor, 40%);
      }
    }

    .item, .right.menu.item {
      padding: 0;

      a:not(.ui.button) {
        padding: 2rem 1rem;

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
          color: @brandColor;
          text-decoration: none;
        }
      }
    }
  }
}

#user-profile-menu{
  z-index: 100;

}

#user-profile-dropdown{
  opacity: 1;
}