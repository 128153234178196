#records-list {
  .item {
      img {
        object-fit: cover;
      }
  }
}

#communities-carousel {
  .ui.image {
    object-fit: cover;


    @media all and (min-width: @largestMobileScreen) {
      position: absolute;
      height: 0.8*@mediumWidth;
      width: 0.8*@mediumWidth;
      top: -2*@defaultPadding;
      left: -1.5*@defaultPadding;
    }

    @media all and (max-width: @largestMobileScreen) {
      width: 100% !important;
      height: auto;
      margin-left: 0;
      margin-right: 0;
    }
  }
}


.ui.image.cern-logo{
  @media all and (max-width: @computerBreakpoint) {
    max-width: 200px !important;
  }
}