.frontpage-main{
  .ui.header{
    border-bottom:  3px solid @secondaryColor;
    display: inline-block;
  }
}

.ui.header {
  &.hero-sitename {
    font-weight: normal !important;
  }
  &.section{
    border-bottom: @accentuationBorder;
  }
}

.site-logo{
  .sub.header{
    @media all and (max-width: @computerBreakpoint) {
      color: white !important;
    }
  }
}
