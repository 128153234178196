@media all and (max-width: @largestTabletScreen) {
  html,
  body {
    width: 100vw;
    overflow-x: clip;
  }
}

.theme.header {
  box-shadow: unset;
  &.frontpage {
    height: @homePageHeroHeight;

    &:after {
      content: " ";
      background: white;
      background: linear-gradient(
        90deg,
        transparent 65%,
        rgba(28, 68, 107, 1) 70%
      );
      position: absolute;
      height: @homePageHeroHexHeight !important;
      width: 100%;
      top: 0;
      z-index: 0;
      @media all and (max-width: @largestTabletScreen) {
        background-color: rgba(28, 68, 107, 1);
        height: calc(@homePageHeroHeight - 3px) !important;
      }
    }

    &:before {
      content: " ";
      position: absolute;
      height: @homePageHeroHeight;
      width: 65%;
      background: white;
      border-bottom: @accentuationBorder;
      @media all and (max-width: @largestTabletScreen) {
        width: 100%;
      }
    }
  }
}

.clip-path {
  &.shadow {
    filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.2));
  }
  height: fit-content;
  width: fit-content;
  position: absolute;

  &.blue {
    z-index: 3;
    filter: drop-shadow(-9px 2px 5px rgba(0, 0, 0, 0.2));
    position: relative;
    margin-left: auto;
    margin-right: auto;
    @media all and (max-width: @computerBreakpoint) {
      margin-left: unset;
      margin-right: unset;
    }
  }

  &.gray {
    z-index: 2;
    position: relative;
    margin-left: auto;
    margin-right: 58%;
    @media only screen and (min-width: @computerBreakpoint) and (max-width: @largestSmallMonitor) {
      margin-right: 65%;
    }
  }

  &.gradient {
    z-index: 1;
    left: 45%;
    position: relative;
    top: calc(
      @homePageHeroHeight - (1 / 3 * @homePageHeroHexHeight)
    ) !important;
  }

  .hex {
    position: absolute;

    &.blue {
      z-index: 3;
      background-image: radial-gradient(
        circle at left,
        @quaternaryColor 5%,
        @quinaryColor 90%
      );
      .hexagon(@homePageHeroHexHeight);
      // special shape due to being on the side
      -webkit-clip-path: polygon(
        25% 0,
        100% 0,
        100% 50%,
        100% 100%,
        25% 100%,
        0% 50%
      );
      clip-path: polygon(25% 0, 100% 0, 100% 50%, 100% 100%, 25% 100%, 0% 50%);

      @media all and (max-width: @largestMobileScreen) {
        width: 100vw !important;
        -webkit-clip-path: polygon(
          30% 0,
          100% 0,
          100% 100%,
          25% 100%,
          0% 80%,
          0% 25%
        );
        clip-path: polygon(30% 0, 100% 0, 100% 100%, 25% 100%, 0% 80%, 0% 25%);
        height: calc(@homePageHeroHeight - 3px) !important;
      }
      @media all and (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {
        width: 100vw !important;
        -webkit-clip-path: polygon(
          15% 0,
          100% 0,
          100% 100%,
          10% 100%,
          0% 85%,
          0% 25%
        );
        clip-path: polygon(15% 0, 100% 0, 100% 100%, 10% 100%, 0% 85%, 0% 25%);
        height: calc(@homePageHeroHeight - 3px) !important;
      }

      .brand.graphic {
        position: relative;
        left: 15%;
        top: 5%;
        @media only screen and (min-width: @computerBreakpoint) and (max-width: @largestSmallMonitor) {
          left: 7%;
        }
        @media all and (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {
          left: 46%;
          top: 13%;
          transform: scale(-1, 1);
        }
        @media all and (max-width: @largestMobileScreen) {
          width: 130vw !important;
          left: -30%;
          top: 20%;
          transform: unset;
        }
      }
    }

    &.gray {
      .hexagon(2/3 * @homePageHeroHexHeight);
      .linear-gradient(90deg, rgba(216, 217, 218, 0.99608) 0%, rgb(207, 208, 210) 100%);
      z-index: 2 !important;
    }

    &.gradient {
      .linear-gradient(-26deg, rgb(217, 218, 220) 0%, rgba(255, 255, 255, 0) 95%);
      opacity: 0.388;
      z-index: 1 !important;
      .hexagon(1/3 * @homePageHeroHexHeight);
    }

    &.icon {
      .hexagon(3em);
      position: absolute;
      display: flex;
      align-items: center;
      text-align: center;
      z-index: 5;

      .large.icon {
        margin-left: 25%;
      }

      &.files {
        background-color: @secondaryColor;
        top: 70%;
        left: 50%;
        .flat-text-shadow-mixin(@secondaryColor);
        @media all and (max-width: @computerBreakpoint) {
          top: 85%;
          left: 30%;
        }
      }

      &.archive {
        background-color: @tertiaryColor;
        top: 25%;
        left: 45%;
        .flat-text-shadow-mixin(@tertiaryColor);
        @media all and (max-width: @computerBreakpoint) {
          left: 62%;
        }
      }

      &.camera {
        background-color: @quinaryColor;
        top: 30%;
        left: 20%;
        .flat-text-shadow-mixin(@quinaryColor);
        @media all and (max-width: @computerBreakpoint) {
          top: 20%;
          left: 20%;
        }
      }

      &.code {
        background-color: @CERNlightgray;
        top: 80%;
        left: 25%;
        .flat-text-shadow-mixin(@CERNlightgray);
        @media all and (max-width: @computerBreakpoint) {
          top: 90%;
          left: 70%;
        }

      }
    }

    &.background {

      //opacity: 0.388;
      background-color: white;
      filter: unset !important;
      z-index: -2 !important;
      .hexagon(5/4 * @homePageHeroHexHeight);
      &.communities {
        position:relative !important;
        left: -65%;
        top: -26rem;
      }
      &:after{
        content: '';
        background-color: white;
        display: inline-block;
        z-index: -3;
        .linear-gradient(270deg, rgba(255,255,255,0) 30%, rgba(243,243,242,1) 80%);
        .hexagon(calc( 5/4* @homePageHeroHexHeight - 1px));
      }
    }
  }
}

.frontpage-main.communities {
  .clip-path {
    z-index: -1;
  }
}
